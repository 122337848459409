<template>
    <Messages />
</template>

<script>
export default {
    components: {
        Messages: () => import('@/components/Messages'),
    },
}
</script>

<style scoped></style>
